const config = {
    apiKey: "AIzaSyCyvziw-lGTFJktkiffZRf-Z8w0-jkKFn8",
    authDomain: "zzpjobs-b7cb9.firebaseapp.com",
    databaseURL: "https://test-5807b-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "zzpjobs-b7cb9",
    storageBucket: "zzpjobs-b7cb9.appspot.com",
    messagingSenderId: "217106145479",
    appId: "1:217106145479:web:bfdb79d210e89007b8dee2",
    measurementId: "G-SKBQ8068P4"
  };

  export default config;